@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        -webkit-tap-highlight-color: transparent;
    }

    body {
        @apply m-0 block bg-surface p-0;
    }
}

@font-face {
    font-family: 'NokaBold';
    src: url('../public/fonts/Noka_Bold.otf') format('opentype');
}

@font-face {
    font-family: 'NokaBlack';
    src: url('../public/fonts/Noka_Black.otf') format('opentype');
}

@font-face {
    font-family: 'NokaHairline';
    src: url('../public/fonts/Noka_Hairline.otf') format('opentype');
}

@font-face {
    font-family: 'NokaLight';
    src: url('../public/fonts/Noka_Light.otf') format('opentype');
}

@font-face {
    font-family: 'NokaMedium';
    src: url('../public/fonts/Noka_Medium.otf') format('opentype');
}

@font-face {
    font-family: 'NokaSemibold';
    src: url('../public/fonts/Noka_Semibold.otf') format('opentype');
}

@keyframes shimmer {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(100%);
    }
}

.shimmer {
    animation: shimmer 2s infinite;
}

.markdown > * {
    all: revert;
}
@layer components {
    /*LANDING PAGE*/
    .padding {
        @apply p-[36px] lg:p-[60px];
    }

    .bg {
        @apply bg-surface-container;
    }

    /*SLIDER WEBKIT (CHROME, SAFARI, OPERA...)*/
    input[type="range"]::-webkit-slider-thumb {
        @apply pointer-events-auto relative z-50 h-[20px] w-[20px] cursor-pointer appearance-none rounded-full border-0 bg-primary shadow-mm-1 hover:ring-[12px] hover:ring-surface-container-highest/30 focus:ring-[12px] focus:ring-surface-container-highest/30;
    }

    /*SLIDER FIREFOX*/
    input[type="range"]::-moz-range-thumb {
        @apply pointer-events-auto relative h-[20px] w-[20px] cursor-pointer appearance-none rounded-full border-0 bg-primary shadow-mm-1 outline-offset-1 hover:ring-[12px] hover:ring-surface-container-highest/30 focus:ring-[12px] focus:ring-surface-container-highest/30;
    }

    /*COMMON BUTTONS*/
    .btn {
        @apply relative flex h-[40px] w-max cursor-pointer items-center justify-center gap-[8px] overflow-hidden rounded-full px-[16px] py-[10px] text-label-large disabled:cursor-not-allowed disabled:opacity-30;
    }

    .filled {
        @apply bg-primary text-on-primary hover:bg-primary-hover;
    }

    .tonal {
        @apply bg-secondary text-on-secondary hover:bg-secondary-hover;
    }

    .outlined {
        @apply border border-on-secondary text-on-secondary hover:bg-secondary-hover hover:backdrop-blur-sm;
    }

    .elevated {
        @apply bg-surface-container-low text-on-secondary shadow-mm-1 hover:bg-secondary-hover hover:backdrop-blur-sm;
    }

    .text {
        @apply text-on-secondary hover:bg-secondary-hover hover:backdrop-blur-sm;
    }

    .standard {
        @apply cursor-pointer text-on-surface hover:bg-surface-container-highest hover:text-on-surface;
    }

    /*ICON BUTTONS*/
    .iconBtn {
        @apply m-[0px]  flex max-h-[40px] max-w-[40px] items-center justify-center gap-[12px] rounded-full p-[8px] disabled:cursor-not-allowed disabled:opacity-30;
    }

    /*FABs*/
    .fab {
        @apply flex h-[56px] w-[56px] items-center gap-[12px] rounded-large px-[16px] py-[16px];
    }

    .fabSmall {
        @apply flex h-[40px] w-[40px] items-center gap-[12px] rounded-medium px-[8px] py-[8px];
    }

    .fabLarge {
        @apply flex h-[96px] w-[96px] items-center justify-center gap-[12px] rounded-large px-[30px] py-[30px];
    }

    .fabExtended {
        @apply flex h-[56px] w-max items-center justify-center gap-[12px] rounded-large px-[16px] py-[16px] text-label-large;
    }

    .surface {
        @apply bg-primary text-on-primary hover:bg-primary-hover disabled:opacity-30;
    }

    .secondary {
        @apply bg-secondary text-on-secondary hover:bg-secondary-hover disabled:opacity-30;
    }

    .tertiary {
        @apply bg-tertiary text-on-tertiary hover:bg-tertiary-hover;
    }

    /*BADGES*/
    .badge {
        @apply flex h-[20px] w-max cursor-pointer items-center gap-[4px] rounded-full bg-error px-[6px] py-[6px] text-label-small text-on-error;
    }

    .dotBadge {
        @apply flex h-[12px] w-[12px] cursor-pointer rounded-full bg-error px-[4px] py-[4px] text-on-error;
    }

    .onIconBadge {
        @apply absolute flex min-h-[8px] min-w-[8px] cursor-pointer rounded-full bg-error px-[4px] text-label-small text-on-error;
    }

    /*CHIPS*/
    .chips {
        @apply flex h-[32px] w-max cursor-pointer items-center gap-[8px] rounded-small bg-surface-container-low px-[8px] text-label-large text-on-surface hover:bg-surface-container-lowest disabled:opacity-30;
    }
    @keyframes gradient-animation {
        0% {
            background-position: 0 50%;
        }
        50% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0 50%;
        }
    }
    @layer utilities {
        .no-scrollbar::-webkit-scrollbar {
            display: none;
        }
        .no-scrollbar {
            -ms-overflow-style: none;
            scrollbar-width: none;
        }
    }

    .bg-custom-gradient {
        background: radial-gradient(at 2% 0%, #d6d6ff 0px, transparent 50%),
        radial-gradient(at 99% 44%, #ffe8a8 0px, transparent 20%),
        radial-gradient(at 32% 92%, #d7cdf9 0px, transparent 50%),
        #ffffff;
        background-size: 200% 200%;
        animation: gradient-animation 30s ease infinite;
    }

    .bg-custom-card-gradient {
        background: radial-gradient(at 2% 0%, #0027f9 0px, transparent 50%),
        radial-gradient(at 99% 44%, #f1c33f 0px, transparent 20%),
        radial-gradient(at 32% 92%, #5a71ea 0px, transparent 50%),
        #ffdf81;
        background-size: 200% 200%;
        animation: gradient-animation 30s ease infinite;
        mix-blend-mode: multiply;
    }

    .text-gradient {
        background: linear-gradient(180deg, rgb(0, 0, 255) 0%, rgb(80, 120, 255) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }
}
